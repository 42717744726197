//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TakeoutQuery from "../../components/qshop/takeoutQuery";
import PopupFullPicker from "../../components/qshop/popup-full-picker";
import selectInfo from "../../components/qshop/selectInfo.js";
import closeWebview from "../../components/qshop/closeWebview";
export default {
  mixins: [selectInfo, closeWebview],
  data() {
    return {};
  },
  components: {
    TakeoutQuery,
    PopupFullPicker
  },
  methods: {
    closeqShop() {
      this.closeWebview();
    }
  }
};

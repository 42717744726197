export const takeoutQuery = {
    'TakeoutCityDictVal': {
        required: true,
        description: '请选择城市'          
    },
    'address': {
        required: true,
        description: '请选择送餐地点'          
    }, 
    'date': {
        required: true,
        description: '请选择送餐日期'          
    }, 
    'time': {
        required: true,
        description: '请选择送餐时间'          
    },            
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from "vuex";
// import PopupFullPicker from '@/components/popup-full-picker'
// import popupPicker from '@/components/popup-picker'
import selectInfo from "./selectInfo.js";
import { takeoutQuery as takeoutQueryValidator } from "../validatorConfig/takeoutQuery";
export default {
  extends: selectInfo,
  data() {
    return {
      pickerDate: "",
      pickerStartDate: new Date(),
      pickerEndDate: new Date(),
      date: this.fomatDateTime(this.pickerDate)
    };
  },
  created() {
    console.log(this.selectDate);
    if (this.selectDate) {
      this.date = this.selectDate;
    }
  },
  computed: {
    ...mapGetters([
      "TakeoutCityDictVal"
      // 'address',
      // 'date',
      // 'time',
      // 'selectDate'
    ]),
    appType: {
      get: function() {
        return this.iStorage.get("appType");
      }
    }
  },
  methods: {
    ...mapMutations(["setMeiCanParams", "setTakeoutParams"]),
    selectCity() {
      const currentRoute = this.$route.path;
      this.$router.push({
        path: "/selectCity",
        query: { mealQueryType: 1, backRoute: currentRoute }
      });
    },
    slectAddress() {
      var params = {
        TakeoutCityDictVal: this.TakeoutCityDictVal,
        address: true,
        date: true,
        time: true
      };
      const validateResult = this.validate(params, takeoutQueryValidator);
      if (!validateResult) return;
      this.$emit("selectAddress");
    },
    setDateRange() {
      var eventStartDate = new Date().getTime();
      var minDate = eventStartDate;
      var maxDate = eventStartDate + 7 * 86400000;
      this.pickerStartDate = new Date(minDate);
      this.pickerEndDate = new Date(maxDate);
    },
    slectDate() {
      // this.Handle('date')
      this.setDateRange();
      this.$refs.dateTimePicker.open();
    },
    dateConfirm() {
      this.date = this.fomatDateTime(this.pickerDate || new Date());
      this.setTakeoutParams({ key: "selectDate", value: this.date });
    },
    fomatDateTime(value) {
      if (!value) return;
      if (value.replace) {
        value = value.replace(/T/g, " ");
        value = value.replace(/-/g, "/");
      }
      var date = new Date(value);
      console.log(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var _date = date.getDate();
      var dateTime = year + "-" + month + "-" + _date;
      return dateTime;
    },
    slectTime() {
      var params = {
        TakeoutCityDictVal: this.TakeoutCityDictVal,
        address: this.address.txt,
        date: this.date,
        time: true
      };
      const validateResult = this.validate(params, takeoutQueryValidator);
      if (!validateResult) return;
      this.$emit("selectTime");
    },
    search() {
      //诺华山德士打开美餐
      if (this.appType == "eventx") {
        var sourceCode = "MeiCan",
          routers = {
            MeiCan: "/businessList"
            // "XiaoMiShu": "/secretary/businessList"
          },
          token = this.iStorage.get("token"),
          isQuery = true, // 只是查看美餐
          //mcOnly 0代表线上和自营餐厅  1代表自营餐厅
          url =
            this.config.meiCan +
            routers[sourceCode] +
            "?tenant_code=" +
            this.appType +
            "&token=" +
            token +
            "&mcOnly=1" +
            "&isQuery=" +
            isQuery;
        window.location.href = url;
        return;
      }
      var params = {
        TakeoutCityDictVal: this.TakeoutCityDictVal,
        address: this.address.txt,
        date: this.date,
        time: this.time.pickupTime
      };
      const validateResult = this.validate(params, takeoutQueryValidator);
      if (!validateResult) return;
      let meiCanParams = {
        externalId: this.address.val,
        targetTime: this.date + " " + this.time.closeTime
      };
      this.setMeiCanParams(meiCanParams);
      this.$router.push({ path: "/takeOutShopList" });
    }
  }
  // components: {
  //     PopupFullPicker,
  //     popupPicker
  // }
};
